/* eslint-disable eqeqeq */

export default (context) => {
  const { $config, $cookies, store, route, $tealium } = context

  const { auth } = store.state
  if (!auth.loggedIn) {
    return
  }

  const userType =
    auth.user.type !== 'v' && auth.user.seller
      ? 'Compratore / Venditore'
      : auth.user.type === 'v'
      ? 'Venditore'
      : 'Compratore'

  /* Double Optin */
  const doubleOptinRoutes = ['area-riservata-dashboard', 'vendo']

  if (
    doubleOptinRoutes.includes(route.name) &&
    auth.user.finalized &&
    $cookies.get('optin') == true
  ) {
    $tealium.track({
      event_name: 'registrazione_ok',
      user_email: auth.user.email,
      login_type: 'Sito',
      user_type: userType,
      optin: 'DOUBLE',
    })
    store.commit('setDoubleOptin', true)

    $cookies.remove('optin', {
      domain: $config.cookieDomain,
      secure: $config.cookieSecure,
      path: '/',
    })

    setTimeout(() => {
      store.commit('setDoubleOptin', false)
    }, 6000)
  }
}

export default function ({ route, store, redirect }) {
  if (store.state.auth.loggedIn) {
    const query = { ...route.query }
    const queryString = Object.keys(query)
      .map((key) => key + '=' + query[key])
      .join('&')
    const url = queryString
      ? `/area-riservata/dashboard?${queryString}`
      : '/area-riservata/dashboard'
    return redirect(url)
  }
}

export default {
  data() {
    return {
      tealiumFormFocused: false,
      tealiumFormLastField: null,
    }
  },

  beforeDestroy() {
    const { tealiumFormLastField } = this

    if (!tealiumFormLastField) {
      return
    }

    this.$tealium.track({
      event_name: 'form_last_field ',
      link_name: 'form_last_field',
      event_type: 'form_last_field',
      form_start_flag: 0,
      form_last_field_flag: 1,
      form_last_field: tealiumFormLastField,
    })
  },

  methods: {
    tealiumFormUpdateLastField(field) {
      this.tealiumFormLastField = field
    },

    onTealiumFormFirstFieldFocus() {
      const { tealiumFormFocused } = this

      if (tealiumFormFocused) {
        return
      }

      this.$tealium.track({
        event_name: 'form_start',
        link_name: 'form_start',
        event_type: 'form_start',
        form_start_flag: 1,
        form_last_field_flag: 0,
      })

      this.tealiumFormFocused = true
    },
  },
}

/* eslint-disable */
export default function (context) {
  // $axios.onRequest((config) => {
  //   console.log('axios request config: ', config)
  //   console.log('Making request to ' + config.url)
  // })

  const { $axios, $config, $cookies } = context

  $axios.onError((error) => {
    if (
      error.response &&
      error.response.status === 401 &&
      error.response.config?.url === `${$config.baseURL}/_api/api/v1/user`
    ) {
      $cookies.remove('auth._token.laravelSanctum', {
        domain: $config.cookieDomain,
        secure: $config.cookieSecure,
        path: '/',
      })
    }

    // eslint-disable-next-line no-console
    console.log(error)
  })
}

import SellGeo from '~/assets/json/sell-geo.json'

export default function ({ route, redirect, params }) {
  if (route.name !== 'sell-geo') return

  const sellCityExists = SellGeo.cities.some((it) => it.slug === params.slug)

  if (!sellCityExists) {
    return redirect(301, '/vendo')
  }
}

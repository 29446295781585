import Vue from 'vue'
import Oruga from '@oruga-ui/oruga'

Vue.use(Oruga, {
  skeleton: {
    override: true,
    rootClass: 'ucrs-skltn',
    itemClass: 'ucrs-skltn__item',
    animationClass: 'ucrs-skltn__item--animated',
    itemRoundedClass: 'ucrs-skltn__item--rounded',
    positionClass: 'ucrs-skltn__item--',
    sizeClass: 'ucrs-skltn__item--',
  },
  tabs: {
    override: true,
    rootClass: 'ucrs-tabs',
    contentClass: 'ucrs-tabs__content',
    navTabsClass: 'ucrs-tabs__nav',
    tabItemWrapperClass: 'ucrs-tabs__navItem',
    itemClass: 'ucrs-tabs__item',
    itemHeaderClass: 'ucrs-tabs__itemHeader-',
    itemHeaderActiveClass: 'ucrs-tabs__itemHeader--active-',
    itemHeaderTextClass: 'ucrs-tabs__itemHeader-text',
    itemHeaderIconClass: 'ucrs-tabs__itemHeader-icon',
    itemHeaderDisabledClass: 'ucrs-tabs__itemHeader--disabled-',
  },
  steps: {
    override: true,
    rootClass: 'ucrs-steps',
    stepsClass: 'ucrs-steps__nav',
    itemClass: 'ucrs-steps__item',
    itemHeaderClass: 'ucrs-steps__header',
    itemHeaderActiveClass: 'ucrs-steps__header--active',
    itemHeaderPreviousClass: 'ucrs-steps__header--previous',
    stepContentClass: 'ucrs-steps__content',
    stepContentTransitioningClass: 'ucrs-steps__content--transition',
    stepMarkerClass: 'ucrs-steps__marker',
    stepNavigationClass: 'ucrs-steps__navigation',
    stepMarkerRoundedClass: 'ucrs-steps__marker--rounded',
    stepLinkClass: 'ucrs-steps__link',
    stepLinkClickableClass: 'ucrs-steps__link--clickable',
    stepLinkLabelClass: 'ucrs-steps__linkLabel',
    animatedClass: 'ucrs-steps--animated',
  },
  tooltip: {
    override: true,
    rootClass: 'ucrs-tip',
    contentClass: 'ucrs-tip__content',
    orderClass: 'ucrs-tip__content--',
    triggerClass: 'ucrs-tip__trigger',
    arrowClass: 'ucrs-tip__arrow',
    arrowOrderClass: 'ucrs-tip__arrow--',
  },
  modal: {
    canCancel: ['escape', 'outside', 'button'],
    override: true,
    rootClass: 'ucrs-modal',
    overlayClass: 'ucrs-modal__overlay',
    contentClass: 'ucrs-modal__container',
  },
  // dropdown: {
  //   override: true,
  //   rootClass: 'ucrs-dropdown',
  //   menuClass: 'ucrs-dropdown__menu',
  //   menuActiveClass: 'ucrs-dropdown__menu--active',
  //   itemClass: 'ucrs-dropdown__item',
  //   itemActiveClass: 'ucrs-dropdown__item--active',
  //   expandedClass: 'ucrs-dropdown--expanded',
  //   disabledClass: 'ucrs-dropdown--disabled',
  // },
  collapse: {
    override: true,
    rootClass: 'ucrs-accordion',
  },
  autocomplete: {
    override: true,
    rootClass: 'ucrs-autocomplete',
    menuClass: 'ucrs-autocomplete__menu',
    menuPositionClass: 'ucrs-autocomplete__menu--',
    expandedClass: 'ucrs-autocomplete__menu--expanded',
    itemGroupTitleClass: 'ucrs-autocomplete__item--title',
    itemClass: 'ucrs-autocomplete__item',
    itemHoverClass: 'ucrs-autocomplete__item--hover',
  },
  field: {
    override: true,
    rootClass: 'ucrs-field',
    labelClass: 'ucrs-field__label',
    messageClass: 'ucrs-field__message',
    variantClass: 'ucrs-field__message--',
  },
  switch: {
    override: true,
    rootClass: 'ucrs-switch',
    variantClass: 'ucrs-switch--',
    labelClass: 'ucrs-switch__label',
    leftLabelClass: 'ucrs-switch__wrapper--leftLabel',
    elementsWrapperClass: 'ucrs-switch__wrapper',
    checkClass: 'ucrs-switch__check',
    checkCheckedClass: 'ucrs-switch__check--checked',
    checkSwitchClass: 'ucrs-switch__check-switch',
  },
  radio: {
    override: true,
    rootClass: 'ucrs-radio',
    checkClass: 'ucrs-radio__check',
    checkCheckedClass: 'ucrs-radio__check--checked',
    checkedClass: 'ucrs-radio--active',
    disabledClass: 'ucrs-radio--disabled',
    labelClass: 'ucrs-radio__label',
    variantClass: 'ucrs-radio--',
  },
  checkbox: {
    override: true,
    rootClass: 'ucrs-checkbox',
    variantClass: 'ucrs-checkbox--',
    checkedClass: 'ucrs-checkbox--checked',
    disabledClass: 'ucrs-checkbox--disabled',
    labelClass: 'ucrs-checkbox__label',
    checkClass: 'ucrs-checkbox__nativeControl',
    checkCheckedClass: 'ucrs-checkbox__nativeControl--checked',
  },
  input: {
    override: true,
    inputClass: 'ucrs-input',
    iconRightClass: 'ucrs-input__icon',
  },
  button: {
    override: true,
    rootClass: 'ucrs-button',
    variantClass: 'ucrs-button--',
    disabledClass: 'ucrs-button--disabled',
  },
  pagination: {
    override: true,
    rootClass: 'ucrs-pagination',
    orderClass: 'ucrs-pagination--',
    listClass: 'ucrs-pagination__list',
    linkClass: 'ucrs-pagination__link',
    linkCurrentClass: 'ucrs-pagination__link--current',
    prevBtnClass: 'ucrs-pagination__link--prev',
    nextBtnClass: 'ucrs-pagination__link--next',
    linkDisabledClass: 'ucrs-pagination__link--disabled',
    ellipsisClass: 'ucrs-pagination__ellipsis',
  },
})

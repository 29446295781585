export default async (context, inject) => {
  const { store, $axios, $tealium, $authUtilities } = context
  const { auth } = store.state

  const Interactions = {
    async save() {
      const { search, favorite, hidden } = store.state.interactions
      const twoHours = 60 * 60 * 2 * 1000

      const userIsLogged = auth.loggedIn
      const userSearch = userIsLogged && search?.email === auth.user.email
      const searchTimeRange = search && Date.now() - search.time < twoHours

      if (userIsLogged && userSearch && searchTimeRange) {
        const { title, email, url, frequency, filter } = search

        try {
          const searchPayload = { title, frequency, email, url, filter }
          const searchRes = await $axios.post(
            '/_api/api/v2/saved-searches',
            searchPayload
          )

          const { data } = searchRes
          $authUtilities.updateUserCount('saved_searches', data.count)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
        }

        store.dispatch('interactions/setSearch', null)
      }

      const favoriteTimeRange =
        favorite && Date.now() - favorite.time < twoHours

      if (userIsLogged && favoriteTimeRange) {
        try {
          const favoriteRes = await $axios.post('_api/api/v2/user/favorite', {
            id: favorite.id,
          })
          const { data } = favoriteRes
          $authUtilities.updateUserCount('favorites', data.count)

          const { tealiumPayload } = favorite
          $tealium.track(tealiumPayload)
        } catch (error) {}

        store.dispatch('interactions/setFavorite', null)
      }

      const hiddenTimeRange = hidden && Date.now() - hidden.time < twoHours

      if (hiddenTimeRange) {
        try {
          const hiddenRes = await $axios.post('_api/api/v2/user/hidden', {
            id: hidden.id,
          })
          const { data } = hiddenRes
          $authUtilities.updateUserCount('hidden', data.count)

          const { tealiumPayload } = hidden
          $tealium.track(tealiumPayload)
        } catch (error) {
          // eslint-disable-next-line no-console
          console.error(error)
        }

        store.dispatch('interactions/setHidden', null)
      }
    },
  }

  inject('interactions', Interactions)

  await Interactions.save()
}

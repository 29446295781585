export default {
  data() {
    return {
      uid: null,
    }
  },
  mounted() {
    this.uid = this._uid.toString()
  },
}

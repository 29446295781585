export default function ({ route, store, redirect }) {
  // Needed because the auth object is not yet updated.
  // By removing auto-import the $auth object is no longer reactive.
  // As of today no docs or forum online has a solution rather than changing to referencing the store directly
  if (!store.state.auth.loggedIn) {
    const query = { ...route.query }
    const queryString = Object.keys(query)
      .map((key) => key + '=' + query[key])
      .join('&')
    const url = queryString
      ? `/area-riservata/login?${queryString}`
      : '/area-riservata/login'
    return redirect(url)
  }
}

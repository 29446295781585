export default ({ app, $tealium }) => {
  app.router.afterEach((from) => {
    // If client routing we don't want to reinitialize the listener.
    if (from.name) return

    let deferredPrompt
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault()
      // Stash the event so it can be triggered later.
      deferredPrompt = e
    })

    window.addEventListener('appinstalled', () => {
      $tealium.track({
        event_name: 'banner_home_aggiungi',
      })

      // Clear the deferredPrompt so it can be garbage collected
      // eslint-disable-next-line no-unused-vars
      deferredPrompt = null
    })
  })
}

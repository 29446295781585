/* eslint-disable promise/param-names */
/* eslint-disable no-var */
/* eslint-disable one-var */

function initGoogleMaps(options) {
  ;((g) => {
    var h,
      a,
      k,
      p = 'The Google Maps JavaScript API',
      c = 'google',
      l = 'importLibrary',
      q = '__ib__',
      m = document,
      b = window
    b = b[c] || (b[c] = {})
    var d = b.maps || (b.maps = {}),
      r = new Set(),
      e = new URLSearchParams(),
      u = () =>
        h ||
        // eslint-disable-next-line no-async-promise-executor
        (h = new Promise(async (f, n) => {
          await (a = m.createElement('script'))
          e.set('libraries', [...r] + '')
          for (k in g)
            e.set(
              k.replace(/[A-Z]/g, (t) => '_' + t[0].toLowerCase()),
              g[k]
            )
          e.set('callback', c + '.maps.' + q)
          a.src = `https://maps.${c}apis.com/maps/api/js?` + e
          // eslint-disable-next-line no-console
          d[q] = f
          a.onerror = () => (h = n(Error(p + ' could not load.')))
          a.nonce = m.querySelector('script[nonce]')?.nonce || ''
          m.head.append(a)
        }))
    d[l]
      ? // eslint-disable-next-line no-console
        console.warn(p + ' only loads once. Ignoring:', g)
      : (d[l] = (f, ...n) => r.add(f) && u().then(() => d[l](f, ...n)))
  })({
    ...options,
    v: 'weekly',
  })
}

// import { Loader } from '@googlemaps/js-api-loader'

export default (context, inject) => {
  const { $config } = context

  // let loader = null

  // async function load(libraries = [], customApiKey = null) {
  //   const { appTracking, googleMapsApiKey, googleMapsApiClient } = $config

  //   const loaderOptions = {}

  //   switch (appTracking) {
  //     case 'dev':
  //       loaderOptions.apiKey = customApiKey || googleMapsApiKey
  //       break

  //     default:
  //       if (customApiKey) {
  //         loaderOptions.apiKey = customApiKey
  //         break
  //       }

  //       loaderOptions.client = googleMapsApiClient
  //       loaderOptions.channel = 'unicreditsubitocasa'
  //   }

  //   if (loader) {
  //     loader.reset()
  //     loader = null
  //   }

  //   loader = new Loader(loaderOptions)

  //   try {
  //     const googleMapsCore = await loader.importLibrary('core')

  //     let loadedLibraries = {}

  //     for (const it of libraries) {
  //       loadedLibraries = {
  //         ...loadedLibraries,
  //         ...(await loader.importLibrary(it)),
  //       }
  //     }

  //     return {
  //       ...googleMapsCore,
  //       ...loadedLibraries,
  //       ...(libraries.includes('marker')
  //         ? { ...(await import('@googlemaps/markerclusterer')) }
  //         : {}),
  //     }
  //   } catch (error) {}
  // }

  async function load(libraries = [], customApiKey = null) {
    const { appTracking, googleMapsApiKey, googleMapsApiClient } = $config

    const loaderOptions = {}

    switch (appTracking) {
      case 'dev':
        loaderOptions.key = customApiKey || googleMapsApiKey
        break

      default:
        if (customApiKey) {
          loaderOptions.key = customApiKey
          break
        }

        // FIXME: Remove client and use only apiKeys.
        loaderOptions.client = googleMapsApiClient
        loaderOptions.channel = 'unicreditsubitocasa'
    }

    // We clean the previous Google Maps instance object (if any).
    if (window.google) {
      window.google = null
    }

    initGoogleMaps(loaderOptions)

    const googleMaps = window.google.maps
    const googleMapsCore = await googleMaps.importLibrary('core')

    let loadedLibraries = {}

    for (const it of libraries) {
      loadedLibraries = {
        ...loadedLibraries,
        ...(await googleMaps.importLibrary(it)),
      }
    }

    return {
      ...googleMapsCore,
      ...loadedLibraries,
      ...(libraries.includes('marker')
        ? { ...(await import('@googlemaps/markerclusterer')) }
        : {}),
    }
  }

  inject('googleMaps', { load })
}

import Vue from 'vue'

Vue.filter('ntoprice', function (value) {
  return new Intl.NumberFormat('it-IT', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(value)
})

Vue.filter('parseInt', function (value) {
  return parseInt(value)
})

Vue.filter('toLocaleString', function (value) {
  return value ? value.toLocaleString('it-IT') : value
})

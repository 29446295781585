// Mixins
import TealiumFormMixin from '~/mixins/tealium-form'

export default {
  mixins: [TealiumFormMixin],

  methods: {
    onFormFieldEvent(payload, dataField, tealiumFieldName) {
      // Avoids Tealium tracking on form auto-compilation, we have to track
      // only real user input events.
      if (this.tealiumFormFocused) {
        this.tealiumFormUpdateLastField(tealiumFieldName || dataField)
      }

      const field = dataField
      this[field] = payload
    },
  },
}

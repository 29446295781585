export default function (context) {
  const { $tealium, route } = context

  if (!$tealium) {
    return
  }

  $tealium.route = route
  $tealium.pageTrack()
}

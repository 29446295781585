import { defineAsyncComponent } from 'vue'

export default ({ $auth, store }, inject) => {
  const AuthUtilities = {
    handleOpenSearch(self) {
      const ModalInner = defineAsyncComponent(() =>
        import('~/components/ModalInner.vue')
      )
      const FormPrivateAreaSearch = defineAsyncComponent(() =>
        import('~/components/FormPrivateAreaSearch.vue')
      )
      self.$oruga.modal.open({
        parent: self,
        width: '123rem',
        animation: 'ucrs-modal-',
        component: ModalInner,
        props: {
          component: FormPrivateAreaSearch,
          componentProps: { user: store.state.auth.user },
        },
      })
    },

    updateUserCount(key, count) {
      const prevUser = store.state.auth.user

      const user = {
        ...prevUser,
        counts: {
          ...prevUser.counts,
          [key]: count,
        },
      }

      $auth.$storage.setState('user', user)
    },
  }

  inject('authUtilities', AuthUtilities)
}
